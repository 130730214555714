import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {TokenStorageService} from "../../_services/token-storage.service";
import {ProduitService} from "../../_services/produit.service";
import {MatDialog} from "@angular/material/dialog";
import {NewsletterDialog} from "../../components/modals/newsletter-dialog/newsletter-dialog.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLoggedIn: boolean = false;
  isCollapsed = true;
  category: any[] = [];

  language: any;

  images: any = {
    brocante: { value: 'cat-brocante.jpg' },
    mercerie: { value: 'cat-mercerie.jpg' },
    textiles: { value: 'cat-textiles.jpg' },
    creations: { value: 'cat-creations.jpg' }
  };

  constructor(public translate: TranslateService, private tokenStorage: TokenStorageService, private produitService: ProduitService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event) => {
      this.category = [];
      this.getAllCategory();
    })
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
    }

    this.getAllCategory();
  }

  logOut(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }

  getAllCategory() {
    let tabCategory: any = [];
    this.produitService.getAllCategory().subscribe((data) => {
      data.forEach((category: any) => {
          this.produitService.getCategories(category.slug).subscribe((data) => {
            tabCategory.push(data);
          });
      });
      this.category = tabCategory;
    });
  }

  openNewsletterDialog() {
    this.dialog.open(NewsletterDialog)
  }
}
