<div class="container-header">
  <div class="row d-flex align-items-center py-3 justify-content-between">
    <div class="col-lg-4 d-none d-lg-block">
      <a href="tel:+33699259572" class="tel">06 99 25 95 72</a>
      <a href="mailto:claude@maisonbleulin.com" class="mail">claude@maisonbleulin.com</a>
      <a (click)="openNewsletterDialog()" class="newsletter">{{'HEADER.NEWSLETTER' | translate}}</a>
    </div>
    <div class="brand-logo col-lg-4 text-center d-lg-block d-flex align-items-center justify-content-between">
      <a href="/">
        <img class="logo" src="/assets/images/logo.png" alt="">
      </a>
      <a routerLink="/panier" class="brand-logo__cart d-lg-none d-block"><i class="fa fa-shopping-basket text-green"></i></a>
    </div>
    <div class="col-lg-3 d-none text-end d-lg-flex flex-column align-items-end">
      <div class="icon-header align-items-center ml-auto">
        <a href="/recherche"><i class="fa fa-search text-green"></i></a>
        <a routerLink="/panier" class="d-lg-block d-none"><i class="fa fa-shopping-basket text-green"></i></a>
        <a *ngIf="!isLoggedIn" href="/connexion"><i class="fa fa-user text-green"></i></a>
        <div class="dropdown" *ngIf="isLoggedIn">
          <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                  aria-expanded="false">
            <i class="fa fa-user text-green"></i>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" [routerLink]="'/profil'">{{'PROFIL.TITLE' | translate}}</a></li>
            <li><a class="dropdown-item" (click)="logOut()">{{'PROFIL.DISCONNECT' | translate}}</a></li>
          </ul>
        </div>
        <a href="https://www.instagram.com/maisonbleulin/" target="_blank"><i
          class="fa fa-instagram text-green"></i></a>
        <div class="btn-group btn-group-lang">
          <select #langSelect (change)="translate.use(langSelect.value)">
            <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                    [selected]="lang === translate.currentLang">{{ lang }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-nav">
  <nav class="navbar navbar-expand-lg">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed">
      <i class="fa-solid fa-bars"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav mx-auto text-center">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" routerLink="/"
             routerLinkActive="active">{{ 'HEADER.MENU.HOME' | translate }}</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
             aria-expanded="false">
            {{ 'HEADER.MENU.CATALOG' | translate }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li class="li-dropdown-menu" *ngFor="let c of category, let i = index">
              <app-dropdown-item [dropdown]="c" [image]="images[c.slug].value"></app-dropdown-item>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/nouveautes"
             routerLinkActive="active">{{ 'HEADER.MENU.NEW-PRODUCTS' | translate }}<span
            class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/actualites"
             routerLinkActive="active">{{ 'HEADER.MENU.NEWS' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/pressbook"
             routerLinkActive="active">{{ 'HEADER.MENU.PRESS-BOOK' | translate }} <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/partenaires"
             routerLinkActive="active">{{ 'HEADER.MENU.PARTNERS' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact" routerLinkActive="active">{{ 'HEADER.MENU.CONTACT' | translate }}
            <span class="sr-only">(current)</span></a>
        </li>
      </ul>

      <div class="icon-header align-items-center ml-auto">
        <a href="/recherche"><i class="fa fa-search text-green"></i></a>
        <a *ngIf="!isLoggedIn" [routerLink]="'/connexion'"><i class="fa fa-user text-green"></i></a>
        <a *ngIf="isLoggedIn" [routerLink]="'/profil'"><i class="fa fa-user text-green"></i></a>
        <a href="https://www.instagram.com/maisonbleulin/" target="_blank"><i
          class="fa fa-instagram text-green"></i></a>
        <div class="btn-group btn-group-lang">
          <select #langSelectMobile (change)="translate.use(langSelectMobile.value)">
            <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                    [selected]="lang === translate.currentLang">{{ lang }}</option>
          </select>
        </div>
      </div>
    </div>
  </nav>
</div>
