import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../_services/auth.service";
import {NotificationService} from "../../_services/notification.service";
import {Route, Router} from "@angular/router";
import {Validators} from "@angular/forms";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent implements OnInit {

  hide = true;
  hide2 = true;

  form: any = {
    email: null,
    name: null,
    prenom: null,
    adresse: null,
    zipCode: null,
    country: null,
    locality: null,
    tel: null,
    password: null,
    confirm_password: null
  }

  isSuccessful = false;
  isSignUpFailed = false;

  errorMessage = '';

  values = '';
  enseigne = '';
  displayLoading = false;

  siteKey = `${environment.siteKey}`;
  captchaAccept: boolean = false;

  constructor(private authService: AuthService, private http: HttpClient, private notificationService: NotificationService, private router: Router) { }

  ngOnInit(): void {
  }

  resolved(captchaResponse: string) {
    if(captchaResponse){
      this.captchaAccept = true;
    }
  }

  onSubmit(): void {
    const {email, name, prenom, adresse, zipCode, country, locality, tel, password, passwordConfirm} = this.form;
    if(this.captchaAccept){
      if(!(password != passwordConfirm)){
        this.authService.register(email, name, prenom, adresse, zipCode, country, locality, tel, password).subscribe(
          data => {
            this.isSuccessful = true;
            this.isSignUpFailed = false;
            this.notificationService.notifyTopRight('Votre demande de création de compte été envoyée avec succès');
            this.router.navigate(['/connexion']);

          },
          err => {
            this.errorMessage = err.error.message;
            this.isSignUpFailed = true;
          }
        );
      }
      else{
        this.notificationService.notifyTopRight('Les mots de passe ne correspondent pas');
      }
    } else {
      this.notificationService.notifyTopRight("Veuillez prouver que vous n'êtes pas un robot.");
    }
  }
}
