import { Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {TokenStorageService} from "../../_services/token-storage.service";
import {AuthService} from "../../_services/auth.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit {

  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  hide = true;
  roles: string[] = [];

  siteKey = `${environment.siteKey}`;
  captchaAccept: boolean = false;

  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }

    if(this.isLoggedIn){

    }
  }

  resolved(captchaResponse: string) {
    if(captchaResponse){
      this.captchaAccept = true;
    }
  }

  onSubmit(): void {
    const {username, password} = this.form;

    this.spinner.show();

    if(this.captchaAccept){
      this.authService.login(username, password).subscribe(
        data => {
          this.spinner.hide();

          this.tokenStorage.saveToken(data.token);
          this.tokenStorage.saveUser(data);

          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.roles = this.tokenStorage.getUser().roles;
          window.location.href = "/";
        },
        err => {
          this.spinner.hide();

          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
        }
      );
    } else {
      this.spinner.hide();
    }
  }

}
