<section class="register-section">
  <form class="register-form" *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <h1 class="connexion-title">{{'REGISTER.TITLE' | translate }}</h1>
    <p class="bienvenue-text">{{ 'REGISTER.PITCH' | translate }}</p>
    <div class="full-width input-container">
      <mat-form-field appearance="outline" class="full-width-3">
        <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.LASTNAME.LABEL' | translate}}</mat-label>
        <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.LASTNAME.LABEL' | translate}}"
               name="name" [(ngModel)]="form.name" required minlength="3"
               maxlength="20" #name="ngModel">
        <mat-error *ngIf="name.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.LASTNAME.ERROR' | translate}}</mat-error>
        <mat-error *ngIf="name.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.LASTNAME.MIN-LENGTH' | translate}}</mat-error>
        <mat-error *ngIf="name.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.LASTNAME.MAX-LENGTH' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width-3">
        <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.FIRSTNAME.LABEL' | translate}}</mat-label>
        <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.FIRSTNAME.LABEL' | translate}}"
               name="prenom" [(ngModel)]="form.prenom" required minlength="3"
               maxlength="20" #prenom="ngModel">
        <mat-error *ngIf="prenom.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.FIRSTNAME.ERROR' | translate}}</mat-error>
        <mat-error *ngIf="prenom.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.FIRSTNAME.MIN-LENGTH' | translate}}</mat-error>
        <mat-error *ngIf="prenom.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.FIRSTNAME.MAX-LENGTH' | translate}}</mat-error>
      </mat-form-field>


      <mat-form-field appearance="outline" class="full-width-3">
        <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.PHONE-NUMBER.LABEL' | translate}}</mat-label>
        <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.PHONE-NUMBER.LABEL' | translate}}"
               name="tel" [(ngModel)]="form.tel" required minlength="10"
               maxlength="10" #tel="ngModel">
        <mat-error *ngIf="tel.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.PHONE-NUMBER.ERROR' | translate}}</mat-error>
        <mat-error *ngIf="tel.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.PHONE-NUMBER.MIN-LENGTH' | translate}}</mat-error>
        <mat-error *ngIf="tel.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.PHONE-NUMBER.MAX-LENGTH' | translate}}</mat-error>
      </mat-form-field>
    </div>

    <div class="full-width input-container">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ZIP-CODE.LABEL' | translate}}</mat-label>
        <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ZIP-CODE.LABEL' | translate}}"
               name="zipCode" [(ngModel)]="form.zipCode" required minlength="3"
               maxlength="20" #zipCode="ngModel">
        <mat-error *ngIf="zipCode.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ZIP-CODE.ERROR' | translate}}</mat-error>
        <mat-error *ngIf="zipCode.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ZIP-CODE.MIN-LENGTH' | translate}}</mat-error>
        <mat-error *ngIf="zipCode.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ZIP-CODE.MAX-LENGTH' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.COUNTRY.LABEL' | translate}}</mat-label>
        <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.COUNTRY.LABEL' | translate}}"
               name="country" [(ngModel)]="form.country" required minlength="3"
               maxlength="20" #country="ngModel">
        <mat-error *ngIf="country.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.COUNTRY.ERROR' | translate}}</mat-error>
        <mat-error *ngIf="country.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.COUNTRY.MIN-LENGTH' | translate}}</mat-error>
        <mat-error *ngIf="country.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.COUNTRY.MAX-LENGTH' | translate}}</mat-error>
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ADDRESS.LABEL' | translate}}</mat-label>
      <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ADDRESS.LABEL' | translate}}"
             name="adresse" [(ngModel)]="form.adresse" required minlength="10"
             maxlength="150" #adresse="ngModel">
      <mat-error *ngIf="adresse.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ADDRESS.ERROR' | translate}}</mat-error>
      <mat-error *ngIf="adresse.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ADDRESS.MIN-LENGTH' | translate}}</mat-error>
      <mat-error *ngIf="adresse.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ADDRESS.MAX-LENGTH' | translate}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>E-mail</mat-label>
      <input matInput type="email" placeholder="E-mail"
             name="email" [(ngModel)]="form.email" required minlength="10"
             maxlength="150" #email="ngModel">
      <mat-error *ngIf="email.errors?.required">{{ 'LOGIN.FORM.FIELDS.EMAIL.ERROR' | translate }}</mat-error>
      <mat-error *ngIf="email.errors?.minlength">{{ 'LOGIN.FIELDS.EMAIL.MIN-LENGTH' | translate }}</mat-error>
      <mat-error *ngIf="email.errors?.maxlength">{{ 'LOGIN.FIELDS.EMAIL.MAX-LENGTH' | translate }}</mat-error>
    </mat-form-field>

    <div class="full-width input-container">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{ 'LOGIN.FORM.FIELDS.PASSWORD.LABEL' | translate }}</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" name="password" id="password" [(ngModel)]="form.password"
               #password="ngModel"
               required>
        <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-hint>
          <div style="color: red" role="alert" *ngIf="password.errors && f.submitted">
            <div *ngIf="password.errors.required">{{ 'LOGIN.FORM.FIELDS.PASSWORD.ERROR' | translate }}</div>
            <div *ngIf="password.errors.minlength">
              Password must be at least 4 characters
            </div>
          </div>
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{ 'REGISTER.CONFIRM-PASSWORD' | translate }}</mat-label>
        <input matInput [type]="hide2 ? 'password' : 'text'" name="passwordConfirm" id="passwordConfirm" [(ngModel)]="form.passwordConfirm"
               #passwordConfirm="ngModel"
               required>
        <button mat-icon-button matSuffix type="button" (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide2">
          <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-hint>
          <div style="color: red" role="alert" *ngIf="passwordConfirm.errors && f.submitted">
            <div *ngIf="passwordConfirm.errors.required">{{ 'LOGIN.FIELDS.PASSWORD.ERROR' | translate }}</div>
            <div *ngIf="passwordConfirm.errors.minlength">
              Password must be at least 4 characters
            </div>
            <div *ngIf="passwordConfirm.value.value != password.value.value">
              Les mots de passes doivent être identiques !
            </div>
          </div>
        </mat-hint>
      </mat-form-field>
    </div>


    <div class="d-flex">
      <input type="checkbox" required="required">
      <label class="control-label label-rgpd p-2">
        {{ 'REGISTER.LEGALS' | translate }}
        <a href="/mentions-legales">{{'REGISTER.SEE-MORE' | translate }}</a>
      </label>
    </div>

    <div class="mb-3">
      <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>
      <mat-error *ngIf="f.submitted && !captchaAccept">Veuillez prouver que vous n'êtes pas un robot.</mat-error>
    </div>

    <div class="login-button-div">
      <a href="/connexion" class="btn btn-outline-primary">{{ 'LOGIN.FORM.BUTTONS.LOGIN' | translate }}</a>
      <button class="btn btn-primary" type="submit">{{ 'LOGIN.FORM.BUTTONS.REGISTER' | translate }}</button>
    </div>
  </form>
</section>
