// APP
import { AppRoutingModule } from './app-routing.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIcon} from "@angular/material/icon";
import {NgxPaginationModule} from 'ngx-pagination';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ReactiveFormsModule } from '@angular/forms';

// _DIRECTIVES
import {HoverColorInverseDirective} from './_directives/hover-color-inverse.directive';

// _HELPERS
// import { authInterceptorProviders } from './_helpers/auth.interceptor';

// _PIPES
import {SlugifyPipe} from "./_pipes/slugify";

// STRUCTURE
import { HeaderComponent } from './structure/header/header.component';
import { FooterComponent } from './structure/footer/footer.component';
// PAGES
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { IntroComponent } from './pages/intro/intro.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsDescribeComponent } from './pages/news-describe/news-describe.component';
import { NewsletterComponent } from './_components/newsletter/newsletter.component';
import { ProductComponent } from './_components/product/product.component';
import { CatalogsComponent } from './pages/catalogs/catalogs.component';
import { CartComponent } from './pages/cart/cart.component';
import { PressbookComponent } from './pages/pressbook/pressbook.component';
import {SlickCarouselModule} from "ngx-slick-carousel";
import {ModalProductComponent} from "./_helpers/modal";
import {ProductDescribeComponent} from "./pages/product-describe/product-describe.component";
import {NumberFieldComponent} from "./components/number-field/number-field.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NewsCatalogComponent} from "./pages/news-catalog/news-catalog.component";
import { SliderComponent } from './pages/home/slider/slider.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PartenairesComponent } from './pages/partenaires/partenaires.component';
import { DropdownItemComponent } from './structure/header/dropdown-item/dropdown-item.component';
// import { MapComponent } from './_components/map/map.component';
import { CartItemComponent } from './pages/cart/cart-item/cart-item.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SearchComponent } from './structure/search/search.component';
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import { ConnexionComponent } from './pages/connexion/connexion.component';
import { InscriptionComponent } from './pages/inscription/inscription.component';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import { QuiSuisJeComponent } from './pages/qui-suis-je/qui-suis-je.component';
import { ProfilComponent } from './pages/profil/profil.component';
import {RouterModule} from "@angular/router";
import { SearchFilterPipe } from './_pipes/search-filter.pipe';
import { ProfilInformationsComponent } from './pages/profil/profil-informations/profil-informations.component';
import { ProfilCommandesComponent } from './pages/profil/profil-commandes/profil-commandes.component';
import { CgvComponent } from './pages/cgv/cgv.component';
import { MentionsLegalesComponent } from './pages/mentions-legales/mentions-legales.component';
import {AuthInterceptor} from "./_services/token-expiration.service";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import { NewsletterDialog } from './components/modals/newsletter-dialog/newsletter-dialog.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {ShareButtonsModule} from "ngx-sharebuttons/buttons";
import {ShareIconsModule} from "ngx-sharebuttons/icons";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { LoaderComponent } from './_components/loader/loader.component';
import { RecaptchaModule } from 'ng-recaptcha';

registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    IntroComponent,
    NewsComponent,
    NewsDescribeComponent,
    HoverColorInverseDirective,
    SlugifyPipe,
    CartComponent,
    PressbookComponent,
    CatalogsComponent,
    NewsletterComponent,
    ProductComponent,
    ModalProductComponent,
    ProductDescribeComponent,
    NumberFieldComponent,
    NewsCatalogComponent,
    SliderComponent,
    ContactComponent,
    PartenairesComponent,
    DropdownItemComponent,
    // MapComponent,
    CartItemComponent,
    SearchComponent,
    ConnexionComponent,
    InscriptionComponent,
    QuiSuisJeComponent,
    ProfilComponent,
    SearchFilterPipe,
    ProfilInformationsComponent,
    ProfilCommandesComponent,
    CgvComponent,
    MentionsLegalesComponent,
    NewsletterDialog,
    LoaderComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    CommonModule,
    SlickCarouselModule,
    NgbModule,
    AutocompleteLibModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    NgxPaginationModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatDialogModule,
    FontAwesomeModule,
    ShareButtonsModule,
    ShareIconsModule,
    RecaptchaModule
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
