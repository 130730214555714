import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {NgForm} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {NotificationService} from "../../_services/notification.service";


const API = `${environment.api}/api/contact`;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  form: any = {
    name: null,
    prenom: null,
    tel: null,
    email: null,
    legals: null,
  };

  siteKey = `${environment.siteKey}`;
  captchaAccept: boolean = false;

  constructor(private http: HttpClient, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  resolved(captchaResponse: string) {
    if(captchaResponse){
      this.captchaAccept = true;
    }
  }

  onFormSubmit(form:NgForm){
    const {name, email, message, phoneNumber, legals} = this.form;

    if(form.valid && this.captchaAccept){
      const body = {
        "name": form.form.value['name'],
        "email": form.form.value['email'],
        "message": form.form.value['message'],
        "phoneNumber": form.form.value['phoneNumber']
      }
      this.http.post<any>(API, body).subscribe(response => {
        this.notificationService.notifyTopRight('Votre message a bien été envoyé');
      },
        err => {
          console.warn("err: ", err);
          this.notificationService.notifyTopRight('Une erreur est survenue');
        });
    }
  }
}
